import React, { useState, useEffect, Fragment } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader1 from "../../components/common/PageHeader1";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import st from "./DynamicForm.module.scss";
import { useNavigate } from "react-router";
import { FilterComponent } from "../../components/common/FilterComponent";

function ProductOffer() {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
  } = useForm();
  const [isDeletemodal, setIsDeletemodal] = useState(false);

  const [table_row, setTable_row] = useState([]);
  // const [ismodal, setIsmodal] = useState(false);
  // const [iseditmodal, setIseditmodal] = useState(false);
  const [user, setuser] = useState(null);
  // const [ismodel, setIsModel] = useState(false);
  // const userData = JSON.parse(localStorage.getItem("userData") ?? {});
  // const [changeStatusTo, setChangeStatusTo] = useState({});

  const columns = () => {
    return [
      {
        name: " ID",
        selector: (row) => row?.product?.id,
        sortable: true,
        minWidth: "140px",
      },
      {
        name: "Product Title",
        selector: (row) => row?.product?.title,
        sortable: true,
        minWidth: "140px",
      },
      {
        name: "Product Image",
        selector: (row) => row?.product_images,
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <>
              {row?.product?.product_images?.length > 0 ? (
                row?.product?.product_images?.slice(0, 1)?.map((item) => {
                  return (
                    <img
                      key={row.id}
                      className="avatar border"
                      src={item?.image_url}
                      alt="productImg"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50px",
                      }}
                    />
                  );
                })
              ) : (
                <span>No Image</span>
              )}
            </>
          );
        },
      },
      {
        name: "Original Price",
        selector: (row) => row?.product?.original_price,
        sortable: true,
        minWidth: "140px",
      },
      {
        name: "Discount Price",
        selector: (row) => row?.product?.discount_price,
        sortable: true,
        minWidth: "140px",
      },

      {
        name: "Offers",
        selector: (row) => row?.product?.user?.full_name,
        sortable: true,
        minWidth: "140px",
        cell: (row) => (
          <>
            <span>{row?.offers ? row?.offers?.length : 0}</span>
          </>
        ),
      },
      {
        name: "ACTION",
        selector: (row) => {},
        sortable: true,
        minWidth: "140px",
        cell: (row) => (
          <div
            id={row?.id}
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            {/* <button
              type="button"
              onClick={() => {
                setIsDeletemodal(true);
                setuser(row);
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-ui-delete text-danger"></i>
            </button> */}
            <button
              type="button"
              onClick={() => {
                navigate("/product-offer-details", { state: row });
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-eye-alt"></i>
            </button>
          </div>
        ),
      },
    ];
  };

  async function getProductList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/product_offers/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      let user = JSON?.parse(localStorage?.getItem("userData")) || {};
      // if (user && user?.is_superuser) {
      setTable_row(
        data
          .filter((item) => item?.product?.user?.id === user?.id)
          .sort((a, b) => {
            // Extract the earliest date from each `offer` array
            const earliestDateA = a?.offer?.length
              ? Math.min(...a.offer.map((o) => new Date(o.date).getTime()))
              : Infinity;

            const earliestDateB = b?.offer?.length
              ? Math.min(...b.offer.map((o) => new Date(o.date).getTime()))
              : Infinity;

            // Compare the earliest dates
            return earliestDateA - earliestDateB; // Ascending order
          })
      );
      // }
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    getProductList();
  }, []);

  // Filtering Product based on Title
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = table_row.filter((item) => {
    return (
      item?.product?.title &&
      item?.product?.title.toLowerCase().includes(filterText.toLowerCase())
    );
  });
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      <div className="container-xxl">
        <PageHeader1 pagetitle="Product Offer" />
        <div className="row clearfix g-3">
          <div className="col-sm-12">
            <div className="card mb-3">
              <div className="card-body">
                <div
                  id="myProjectTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <DataTable
                        columns={columns()}
                        defaultSortField="title"
                        pagination
                        selectableRows={false}
                        className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                        highlightOnHover={true}
                        data={filteredItems}
                        subHeader
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductOffer;
