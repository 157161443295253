import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import TimeComponent from "../../common/TimeComponent";
import app from "../../../firebase/configurations";
import st from "../../../style.module.scss";
import { groupMessagesByDate } from "../../../helper/changeDateFormat";

let db = getFirestore(app);

function ChatBox({ checkMediaQuery, checkMediaQueryStatus }) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const chatContainerRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  let params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  let receiver_id = location?.state?.buyer
    ? +params?.sender_id
    : +params?.receiver_id;
  let sender_id = location?.state?.buyer
    ? +params?.receiver_id
    : +params?.sender_id;
  const isAdmin = userData?.is_superuser;

  useEffect(() => {
    const docId = `${receiver_id}-${sender_id}`; // Construct your docId based on your logic
    const messagesRef = collection(doc(db, "chatrooms", docId), "messages");
    const messagesQuery = query(messagesRef, orderBy("createdAt", "asc"));

    const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
      const messagesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesList);
      markMessagesAsSeen(messagesList);
    });

    return () => unsubscribe();
  }, [receiver_id, sender_id]);

  const markMessagesAsSeen = async (messagesList) => {
    if (!isAdmin) {
      for (const message of messagesList) {
        if (message.sentTo === sender_id) {
          const messageRef = doc(
            db,
            "chatrooms",
            `${receiver_id}-${sender_id}`,
            "messages",
            message.id
          );
          await updateDoc(messageRef, { messagesSeen: false });

          const chatHeadRef = doc(
            db,
            "chatrooms",
            "chatHead",
            sender_id.toString(),
            receiver_id.toString()
          );

          await updateDoc(chatHeadRef, { messagesCounter: 0 });
        }
      }
    }
  };

  const sendMessage = async () => {
    const date = new Date().toISOString();
    if (inputText.trim()) {
      const newMessage = {
        text: inputText,
        sentBy: sender_id,
        sentTo: receiver_id,
        createdAt: new Date().toISOString(),
        senderImage: location?.state?.communication?.receiverImage,
        receiverImage: location?.state?.communication?.senderImage,
        senderName: location?.state?.communication?.receiverName,
        receiverName: location?.state?.communication?.senderName,
        date_time: date,
        userType: "is_Vendor",
        messagesSeen: true,
        productData: [],
        offerValue: "",
      };

      setInputText("");
      setMessages((prevMessages) => [newMessage, ...prevMessages]);

      const docId = `${receiver_id}-${sender_id}`;
      const chatRef = doc(db, "chatrooms", docId);
      const messagesCollectionRef = collection(chatRef, "messages");

      await addDoc(messagesCollectionRef, {
        ...newMessage,
        createdAt: serverTimestamp(),
      });

      await updateChatHead(
        sender_id,
        receiver_id,
        newMessage.text,
        location?.state?.communication?.senderImage,
        location?.state?.communication?.senderName,
        "sender"
      );

      await updateChatHead(
        receiver_id,
        sender_id,
        newMessage.text,
        location?.state?.communication?.receiverImage,
        location?.state?.communication?.receiverName,
        "receiver"
      );
    }

    scrollToBottom();
  };

  const updateChatHead = async (
    senderId,
    receiverId,
    lastMessage,
    senderImage,
    name,
    type
  ) => {
    const date = new Date().toISOString();
    const trueCount = messages.filter(
      (item) => item.messagesSeen === true
    ).length;
    const chatHeadRef = doc(
      db,
      "chatrooms",
      "chatHead",
      senderId.toString(),
      receiverId.toString()
    );

    await updateDoc(chatHeadRef, {
      sender_id: senderId,
      receiver_id: receiverId,
      message: lastMessage,
      status: senderId === sender_id ? 0 : 1,
      name:
        type === "sender"
          ? location?.state?.communication?.senderName
          : location?.state?.communication?.receiverName,
      image:
        type === "sender"
          ? location?.state?.communication?.senderImage
          : location?.state?.communication?.receiverImage,
      date_time: date,
      chat_visibility: null,
      senderImage:
        type === "sender"
          ? location?.state?.communication?.receiverImage
          : location?.state?.communication?.senderImage,
      receiverImage:
        type === "sender"
          ? location?.state?.communication?.senderImage
          : location?.state?.communication?.receiverImage,
      senderName:
        type === "sender"
          ? location?.state?.communication?.receiverName
          : location?.state?.communication?.senderName,
      receiverName:
        type === "sender"
          ? location?.state?.communication?.senderName
          : location?.state?.communication?.receiverName,
      userType: "is_Vendor",
      messagesCounter:
        senderId === userData?.id ? 0 : trueCount === 0 ? 1 : trueCount,
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  console.log(messages, "oooooooooooo");
  const groupedMessages = groupMessagesByDate(messages);

  return (
    <>
      <div className="chat-header d-flex justify-content-between align-items-center border-bottom pb-3 marginTopMobile30">
        <div className="d-flex align-items-center">
          <i
            className="icofont-arrow-left chatBackIcon"
            onClick={() => {
              checkMediaQuery(false);
            }}
          ></i>
          <span>
            <img
              className="avatar rounded"
              src={`${
                location?.state?.communication?.image
                  ? location?.state?.communication?.image
                  : "https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180"
              }`}
              alt="avatar"
            />
          </span>
          <div className="ms-3">
            <h6 className="mb-0">{location?.state?.communication?.name}</h6>
          </div>
        </div>
        <div className="d-flex"></div>
      </div>

      <ul
        className="chat-history list-unstyled mb-0 py-lg-5 py-md-4 py-3 flex-grow-1"
        ref={chatContainerRef}
      >
        {Object.entries(groupedMessages).map(([label, messagesOnDate]) => (
          <div key={label}>
            {/* Render Day/Date Header */}
            <li className="date-header text-center my-3">{label}</li>

            {/* Render Messages */}
            {messagesOnDate?.map((d, i) => (
              <li
                key={"s" + i}
                className={
                  location?.state?.buyer
                    ? d.userType === "is_Buyer"
                      ? "mb-3 d-flex flex-row-reverse align-items-end"
                      : "mb-3 d-flex flex-row align-items-end"
                    : d.userType === "is_Buyer"
                    ? "mb-3 d-flex flex-row align-items-end"
                    : "mb-3 d-flex flex-row-reverse align-items-end"
                }
              >
                <div
                  className={`max-width-70 ${
                    location?.state?.buyer
                      ? d?.userType === "is_Buyer"
                        ? "text-right text-end"
                        : ""
                      : d?.userType === "is_Buyer"
                      ? ""
                      : "text-right text-end"
                  }`}
                >
                  {d?.offerValue !== "" &&
                  d?.text === "Send a offer to vendor" &&
                  Object.keys(d?.productData).length > 0 ? (
                    <div
                      className={`${st.chatCard_boxMain} ${
                        location?.state?.buyer
                          ? d.userType === "is_Buyer"
                            ? "text-right text-end ms-auto"
                            : ""
                          : d.userType === "is_Buyer"
                          ? ""
                          : "text-right text-end ms-auto"
                      }`}
                    >
                      <div
                        className={`${st.chatCard_box}`}
                        onClick={() => {
                          navigate("/single-product-details", {
                            state: d?.productData,
                          });
                        }}
                      >
                        <div className={`${st.chatCard_img}`}>
                          <img
                            src={
                              d?.productData?.product_images?.[0]?.image_url
                                ? d?.productData?.product_images?.[0]?.image_url
                                : "https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180"
                            }
                            alt=""
                          />
                        </div>
                        <div className={`${st.chatCard_content}`}>
                          <h3>{d?.productData?.title}</h3>
                          <h4>
                            Tsh {d?.productData?.discount_price}{" "}
                            <span>Tsh {d?.productData?.original_price}</span>
                          </h4>
                          <p>{d?.productData?.description}</p>
                          <h5>
                            Discount price :{" "}
                            <span>Tsh {d?.productData?.discount_price}</span>
                          </h5>
                          <h5>
                            Negotiation price : <span>Tsh {d?.offerValue}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`card border-0 p-2 d-inline-flex leftColor ${
                        location?.state?.buyer
                          ? d?.userType === "is_Buyer"
                            ? "bg-primary text-light"
                            : ""
                          : d?.userType === "is_Buyer"
                          ? ""
                          : "bg-primary text-light"
                      }`}
                    >
                      <div className="message">{d?.text}</div>
                      <div className="user-info mb-0">
                        <span className="text-muted small">
                          <TimeComponent
                            dateString={d?.date_time}
                            showOnlyTime={true}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </div>
        ))}
      </ul>
      {!isAdmin && (
        <div className="chatButton">
          <textarea
            className="form-control"
            placeholder="Enter text here..."
            value={inputText}
            onChange={(e) => {
              setInputText(e.target.value);
            }}
          ></textarea>
          <button type="buttton" onClick={sendMessage}>
            Send
          </button>
        </div>
      )}
    </>
  );
}
export default ChatBox;
