export function changeDateFormat(inputDate) {
  // Parse the input date
  const date = new Date(inputDate);
  // Format the date as '21 December, 2023'
  const options = { day: "2-digit", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
}

const getDayLabel = (date) => {
  const today = new Date();
  const messageDate = new Date(date);

  // Calculate the difference in days
  const diffTime = today - messageDate;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) return "Today";
  if (diffDays === 1) return "Yesterday";
  if (diffDays < 7) {
    return messageDate.toLocaleDateString("en-US", { weekday: "long" });
  }

  // For dates older than last week, return the full date
  return messageDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const groupMessagesByDate = (messages) => {
  return messages.reduce((groups, message) => {
    const date = new Date(message.date_time).toISOString().split("T")[0];
    const label = getDayLabel(date); // Get the day label
    if (!groups[label]) {
      groups[label] = [];
    }
    groups[label].push(message);
    return groups;
  }, {});
};
