import React from "react";

const TimeComponent = ({ dateString, showOnlyTime = false }) => {
  const date = new Date(dateString);
  const today = new Date();
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Helper functions
  const isToday = (date) => date.toDateString() === today.toDateString();
  const isYesterday = (date) => {
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    return date.toDateString() === yesterday.toDateString();
  };
  const isWithinLastWeek = (date) => {
    const lastWeek = new Date();
    lastWeek.setDate(today.getDate() - 7);
    return date > lastWeek;
  };

  // Format the time
  const formatTime = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour clock
    return `${hours}:${minutes} ${ampm}`;
  };

  // Determine the display format
  let displayDate;
  if (showOnlyTime) {
    // Always show time in hh:mm AM/PM format
    displayDate = formatTime(date);
  } else {
    if (isToday(date)) {
      displayDate = formatTime(date);
    } else if (isYesterday(date)) {
      displayDate = "Yesterday";
    } else if (isWithinLastWeek(date)) {
      displayDate = dayNames[date.getDay()];
    } else {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      displayDate = `${day}-${month}-${year}`;
    }
  }

  return <div>{displayDate}</div>;
};

export default TimeComponent;
